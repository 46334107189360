import { Search, Icon, Swipe, SwipeItem, Tag, Grid, GridItem, Image, List } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Call from '../../common/Call.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Call: Call,
        Clue: Clue,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tag.name]: Tag,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [Image.name]: Image,
        [List.name]: List
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            keyword: '',
            classifys: [],
            classifyCode: '',
            page: { current: 0, size: 20, last: 0 },
            productList: [
                { code: '1', name: '自学考试（成教）', desc: '不进入学校就读，没有入学考试，没有学籍，自己学习课程后通过考试考取学历文凭，无固定学年，每门课程全部通过就能办理毕业。只讲究文凭高低，无学校好坏区别。自考获取本科学历后，可直接参与统招考研、考博', target: '成人教育、宽进严出，适合有足够学习时间的上班族、待业人员等，有一定难度！' },
                { code: '2', name: '成人高考（成教）', desc: '需参加每年10月的国家统一的入学考试，取得学校录取通知书后，次年三月注册学籍，学制为2.5-3年，有固定学年', target: '成人教育、严进宽出，适合工作较忙，不着急取得毕业证书，想轻松获证的人群，通过率高，同等学历含金量不如自考' },
                { code: '3', name: '网络教育/远程教育（成教）', desc: '在线授课，入学门槛比较低，招生对象不受年龄和先前学历限制，取证简单，而且依靠名校，收费较高，网络教育学制为两年半', target: '成人教育、宽进宽出，适合有一定经济实力且对取证时间没有太多要求的考生' },
                { code: '4', name: '国家开放大学（成教）', desc: '一般指国家开放大学，成人教育的四种之一，国家承认，学信网终身可查，以现代信息技术为支撑，学历教育与非学历教育并举，实施远程开放教育的新型高等学校', target: '宽进宽出，没有远程教育的学校名气大，含金量最低' }
            ]
        }
    },
    mounted () {
        this.$refs.share.default('main')
        this.createVistClue()
    },
    methods: {
        async retrieveDisplayClassifyList () {
            var pd = { operatorCode: 1, type: 'HOT' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/labor/classify/retrieveDisplayClassifyList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifys = res.data
            }
        },
        search () {
            window.sessionStorage.setItem(window.const.global.LABOR_SEARCH_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/labor/search' })
        },
        siftSearch (condition) {
            this.classifyCode = condition.classifyCode
            this.search()
        },
        classifySearch (val) {
            window.sessionStorage.setItem(window.const.global.LABOR_SEARCH_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/labor/search', query: { countryCode: val } })
        },
        async retrieveHotProductPage () {
            // if (!this.finished) {
            //     this.loadingShow = true
            //     this.page.current = this.page.current + 1
            //     var pd = { type: 'HOT', operatorCode: '1', current: this.page.current, size: this.page.size }
            //     var { data: res } = await this.$http.post(this.BMS_URL + '/labor/productAPC/retrieveDisplayProductPage', this.$qs.stringify(pd))
            //     if (res.status === '200') {
            //         this.productList = this.productList.concat(res.data)
            //         this.page.last = res.page.last
            //         if (res.data.length <= 0) {
            //             this.finished = true
            //         }
            //     }
            //     this.loadingShow = false
            // }
        },
        detail (code) {
            var key = window.const.global.LABOR_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/labor/detail', query: { code: code } })
        },
        async createOrder (code) {
            this.loadingShow = true
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
            var pd = {
                productCode: code,
                userCode: user,
                operatorCode: operator,
                sellerCode: seller,
                refereeCode: referee
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/degree/order/createOrder', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.createOrderClue(code, res.data.code)
                this.$dialog.alert({
                    title: '咨询申请',
                    message: '咨询申请成功,请耐心等待或到店咨询'
                })
            } else {
                this.$dialog.alert({
                    title: '申请失败',
                    message: res.msg
                })
            }
        },
        createVistClue () {
            var param = { url: window.location.href }
            var content = '学历提升访问'
            this.$refs.clue.createClue('DEGREE_VIST_HOME', '0', JSON.stringify(param), content)
        },
        createOrderClue (code, orderCode) {
            var param = { productCode: code, orderCode: orderCode, url: window.location.href }
            var content = '学历提升订单'
            this.$refs.clue.createClue('DEGREE_ORDER_DEGREE', orderCode, JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.DEGREE_HOME_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
